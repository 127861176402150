<template>
    <div>
        <div class="row data-form">
            <div class="col-12">
                <div class="border px-5 pt-5 pb-2 mb-4 rounded">
                    <div class="row">
                        <div class="col-12 col-md-6 col-lg">
                            <b-form-group :label="$t('student_number')">
                                {{ formObj.student_number }}
                            </b-form-group>
                        </div>
                        <div class="col-12 col-md-6 col-lg">
                            <b-form-group :label="$t('name_surname')">
                                {{ formObj.name + ' ' + formObj.surname }}
                            </b-form-group>
                        </div>
                        <div class="col-12 col-md-6 col-lg">
                            <b-form-group :label="$t('faculty')">
                                {{ formObj.faculty_name }}
                            </b-form-group>
                        </div>
                        <div class="col-12 col-md-6 col-lg">
                            <b-form-group :label="$t('program')">
                                {{ formObj.program_name }}
                            </b-form-group>
                        </div>
                        <div class="col-12 col-md-6 col-lg-1">
                            <b-form-group :label="$t('class')">
                                {{ formObj.class_name }}
                            </b-form-group>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <b-table
            :empty-text="this.$t('there_are_no_records')"
            bordered
            striped
            responsive
            :items="documentRequests"
            :fields="documentRequestFields"
            show-empty
            class="mb-5 table-dropdown no-scrollbar border rounded">
            <template #cell(buttons)="row">
                <b-dropdown variant="link btn-sm" boundary="window" no-caret>
                    <template #button-content>
                        <i class="ri-more-fill"></i>
                    </template>
                    <div id="dropdownListHead">
                        <b-dropdown-item @click="row.item.prevEbys.callback(row.item.language)" v-if="checkPermission('documentrequest_sendebys')">
                            <i class="ri-mail-send-line align-middle top-minus-1 mr-3 text-muted"></i> {{$t('ebys_send')}}
                        </b-dropdown-item>
                        <b-dropdown-item @click="updateStatusFormShow(row.item)" v-if="checkPermission('documentrequest_updatestatus')">
                            <i class="ri-file-transfer-line align-middle top-minus-1 mr-3 text-muted"></i> {{$t('status_update')}}
                        </b-dropdown-item>
                        <b-dropdown-item @click="downloadDocument(row.item)" v-if="row.item.signature_type_code == 'electronic' && checkPermission('documentrequest_downloaddocument')">
                            <i class="ri-file-download-line align-middle top-minus-1 mr-3 text-muted"></i> {{$t('download')}}
                        </b-dropdown-item>
                        <b-dropdown-item @click="row.item.prev.callback(row.item.language)" v-if="row.item.signature_type_code != 'electronic'">
                            <i class="ri-file-list-line align-middle top-minus-1 mr-3 text-muted"></i> {{$t('document')}}
                        </b-dropdown-item>
                    </div>
                </b-dropdown>
            </template>
        </b-table>
        <CommonModal ref="updateStatusFormModal" size="md" :onHideOnlyX="true">
            <template v-slot:CommonModalTitle>
                {{ $t('status_update').toUpper() }}
            </template>
            <template v-slot:CommonModalContent>
                <update-status-form :id="requestId" :currentStatus="currentStatus" @hide="updateStatusFormSuccess" />
            </template>
        </CommonModal>
    </div>
</template>

<script>
// Components
import documentData from "../data/Documents";

// Services
import DocumentRequestService from "@/services/DocumentRequestService";

// Other
import CommonModal from "@/components/elements/CommonModal";
import UpdateStatusForm from "./UpdateStatusForm";
import qs from "qs";
import AppLayout from "@/layouts/AppLayout"
import {EventBus} from "@/main";


export default {
    components: {
        CommonModal,
        UpdateStatusForm,
        AppLayout
    },
    props: {
        formObj: {
            type: Object,
        }
    },
    data() {
        return {
            requestId: 0,
            currentStatus: null,
            documentRequests: [],
            documentRequestFields: [
                {
                    key: 'buttons',
                    label: ''
                },
                {
                    key: 'document_type',
                    label: this.$t('document_type'),
                },
                {
                    key: 'count',
                    label: this.$t('count'),
                },
                {
                    key: 'date',
                    label: this.$t('date').toUpper(),
                },
                {
                    key: 'signature_type',
                    label: this.$t('signature_type'),
                },
                {
                    key: 'type_of_delivery',
                    label: this.$t('type_of_delivery').toUpper(),
                },
                {
                    key: 'address',
                    label: this.$t('address').toUpper(),
                },
                {
                    key: 'explanation',
                    label: this.$t('explanation'),
                },
                {
                    key: 'price',
                    label: this.$t('price'),
                },
                {
                    key: 'status',
                    label: this.$t('status'),
                },
                {
                    key: 'id',
                    label: '',
                    thClass: 'd-none',
                    tdClass: 'd-none'
                },
            ],
        }
    },
    watch: {
        formObj: function (val) {
            this.get(val)
        }
    },
    async created() {
        await this.get(this.formObj)
    },
    methods: {
        get(obj) {
            let config = {
                params: {
                    filter: {
                        id: this.formObj.id
                    }
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            }
            DocumentRequestService.getStudentDocuments(obj.student_program_id, config).then(response => {
                this.documentRequests = []
                response.data.data.forEach(item => {
                    this.documentRequests.push({
                        document_type: item.document_type,
                        count: item.count,
                        date: item.date,
                        signature_type: item.signature_type,
                        signature_type_code: item.signature_type_code,
                        type_of_delivery: item.delivery_type,
                        address: item.address,
                        explanation: item.explanation,
                        price: item.price == 0 ? this.$t('priceless') : item.price + ' TL',
                        status: item.status,
                        id: item.id,
                        language: item.language,
                        prev: documentData(this.formObj, item.document_type_code, null),
                        prevEbys: documentData(this.formObj, item.document_type_code, {
                            actionButton: true,
                            actionButtonLabel: this.$t('ebys_send'),
                            actionFunc: () => {
                                this.sendToEbys(item.id)
                            }
                        })
                    })
                })
            }).catch(e => {
            })
        },

        downloadDocument(row) {
            DocumentRequestService.downloadDocument(this.formObj.student_program_id, row.id).then(response => {
                this._downloadFile(response, row.document_type + '.pdf')
            }).catch(e => {
                this.$toast.error(this.$t('api.' + e.data.message));
            })
        },

        sendToEbys(id) {
            this.$swal.fire({
                text: this.$t('ebys_send_statement'),
                showCancelButton: true,
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('no'),
            }).then((result) => {
                if (result.isConfirmed) {
                    DocumentRequestService.sendEbys(id).then(response => {
                    }).catch(e => {
                        if (e.status == '404' || e.status == '406') { ///406 geldiği zaman değiştirilecek
                            if (e.data.message) {
                                this.$toast.error(this.$t('api.' + e.data.message));
                            }
                        }
                    })
                }
            })
        },

        updateStatusFormShow(obj) {
            this.requestId = obj.id
            this.currentStatus = obj.status
            this.$refs.updateStatusFormModal.$refs.commonModal.show()
        },
        updateStatusFormSuccess() {
            this.$refs.updateStatusFormModal.$refs.commonModal.hide()
            this.get(this.formObj)
        },


    },


}
</script>
